import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import './custom.css';
import ProductList from './pages/ProductList';
import { NavBar } from './components/NavBar';
import { productCategoryRoutes, routes } from './route/productsCategoryRoutes';
import { ToastrList } from './components/toastr/ToastrList';
import ServiceLayer from './components/ServiceLayer';
import ScrollToTop from './components/scroller/ScrollToTop';
import translationService from './services/translation.service';
import Spinner from './components/loading/Spinner';
import Admin from './components/Admin/Admin';
import { Bounce, ToastContainer } from 'react-toastify';
import Navigation from './route/navigation';

export const isAdActive = true;

export default function App() {
  return (
    <div className='App'>
      <ToastrList />
      <BrowserRouter>
        <ScrollToTop>
          <Navigation/>
        </ScrollToTop>
        <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Bounce}
          closeButton={false}
        />
      </BrowserRouter>
    </div>
  );
}
