import React, { Suspense } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router';
import Spinner from 'src/components/loading/Spinner';
import { NavBar } from 'src/components/NavBar';
import ServiceLayer from 'src/components/ServiceLayer';
import { productCategoryRoutes, routes } from './productsCategoryRoutes';
import ProductList from 'src/pages/ProductList';
import Home from 'src/pages/Home';
import Admin from 'src/components/Admin/Admin';
import translationService from 'src/services/translation.service';
import { getRoute } from 'src/services/routes.service';

const Navigation = () => {
    const location = useLocation();
    return (
        <main>
            {location.pathname !== getRoute(`order_confirmation`) && <NavBar />}
            <Suspense fallback={<Spinner />}>
                <Routes>
                    <Route path='/:lang' element={<ServiceLayer />}>
                        {routes.map((route, index) => (
                            <Route key={index} path={route.path} element={<route.element />} />
                        ))}
                        {productCategoryRoutes.map((pcr, index) => (
                            <Route
                                key={index}
                                path={pcr.path}
                                element={
                                    <ProductList 
                                        title={pcr.title} 
                                        header={pcr.header} 
                                        category={pcr.category} 
                                    />
                                }
                            />
                        ))}
                        <Route path='uk' element={<Home />} />
                        <Route path='admin' element={<Admin />} />
                    </Route>
                    <Route path='*' element={<Navigate to={`/${translationService.getPrefferedLanguageOrDefault('uk')}`} />} />
                </Routes>
            </Suspense>
        </main>
    )
}

export default Navigation;
